import React, { useContext } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Logo } from 'components';
import PropTypes from 'prop-types';
import { appContext } from 'providers/appProvider';
import { Link } from 'gatsby';

import AnchorLink from 'react-anchor-link-smooth-scroll';

import imgLogo from 'images/xyz-client-logo.png';
import EphedrineLogoImg from 'images/logo-ephedrine-colored.png';
import ParLogoImg from 'images/logo-par.png';

const PatientFooter = () => {
  const { appConfigs } = useContext(appContext);
  return (
    <div className="main-footer-content-wrapper">
      <div className="gutter-all footer-content">
        <div className="inner-content">
          <p className="copyright-text">
            ©&nbsp;2023 Par Pharmaceutical, Inc.&nbsp;All rights reserved.
            <br />
            <span className="bold">
              <a
                className="link-privacy-legal"
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.endo.com/privacy-legal"
              >
                Privacy/Legal
              </a>
              &nbsp;
              {appConfigs.metaData.PATIENT_JOB_CODE}/May{' '}
              {appConfigs.metaData.CURRENT_YEAR}{' '}
            </span>
            &nbsp;
            <Link target="_blank" href="https://www.parpharm.com">
              www.parpharm.com
            </Link>
            &nbsp;&nbsp;845-573-5500
            {/* {appConfigs.metaData.CURRENT_YEAR} XYZ Pharma, Inc. All
            rights reserved. <br />
            {appConfigs.metaData.PATIENT_JOB_CODE} */}
          </p>
        </div>
      </div>
    </div>
  );
};

const HcpFooter = () => {
  const { appConfigs } = useContext(appContext);
  return (
    <div className="main-footer-content-wrapper">
      <div className="gutter-all footer-content">
        <div className="inner-content">
          <Logo
            image={imgLogo}
            url="https://www.deerfieldagency.com"
            target="_blank"
            className="client-logo"
          />
          <p className="copyright-text">
            BRANDX<sup>®</sup> is a registered trademark of XYZ Pharma, Inc.{' '}
            <br />© {appConfigs.metaData.CURRENT_YEAR} XYZ Pharma, Inc. All
            rights reserved. <br />
            {appConfigs.metaData.HCP_JOB_CODE} March 2020
          </p>
        </div>
      </div>
    </div>
  );
};

const FooterUtilityNav = ({ className, ...props }) => {
  const { topOffset } = useContext(appContext);
  return (
    <div {...props} className={className}>
      <div className="gutter-all footer-content">
        <Row>
          <Col xs={12} md={2}>
            <div className="container-footer-logo logo-ephedrine">
              <Logo
                image={EphedrineLogoImg}
                target="_blank"
                className="client-logo"
              />
            </div>
          </Col>
          <Col xs={12} md={8}>
            <ul className="utility-list">
              <li>
                <Link to="/workflow-scenarios">Workflow Scenarios</Link>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.endodocuments.com/EPHEDRINE_5/PI"
                >
                  Prescribing Information
                </a>
              </li>
              <li>
                <AnchorLink
                  href="#normal-chappy-isi"
                  className="btn-expand-isi gutter-right"
                  offset={topOffset}
                >
                  Important Safety Information
                </AnchorLink>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.parpharm.com/contact-us/"
                >
                  Contact
                </a>
              </li>
              <li>
                <a
                  className="ot-sdk-show-settings"
                  style={{ cursor: 'pointer' }}
                >
                  Cookies Settings
                </a>
              </li>
            </ul>
          </Col>
          <Col xs={12} md={2}>
            <div className="container-footer-logo logo-par">
              <Logo
                image={ParLogoImg}
                target="_blank"
                className="client-logo"
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default function Footer({ indication, ...props }) {
  return (
    <footer {...props} className="footer-wrapper">
      <FooterUtilityNav className="footer-utility-nav" />
      {indication === 'hcp' && <HcpFooter />}
      {indication === 'patient' && <PatientFooter />}
    </footer>
  );
}

Footer.propTypes = {
  indication: PropTypes.string.isRequired,
};
