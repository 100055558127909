import React from 'react';
import PropTypes from 'prop-types';

export default function Logo({image, className, url, ...props}) {
  return (
    <a {...props} href={url} className={`logo ${className}`}>
      <img src={image} alt="Logo" />
    </a>
  );
}

Logo.propTypes = {
  image: PropTypes.string.isRequired,
  url: PropTypes.string,
  className: PropTypes.string,
};
