import React, { useState, useEffect, useRef } from 'react';
import NavBar from '@ilama007/nav-bar';
import '@ilama007/nav-bar/dist/index.css';
import PropTypes from 'prop-types';
import HcpNav from './nav-contents/HcpNav';
import PatientNav from './nav-contents/PatientNav';

export default function Nav({ indication }) {
  const [triggerClose, setTriggerClose] = useState(false);

  const closeHamburger = () => {
    setTriggerClose(true);
    // setTimeout(() => {
    //   setTriggerClose(false);
    // }, 10);
  };

  return (
    <div className="container-nav-hamburger">
      <NavBar mobileBreakPoint={767} triggerHamburgerClose={triggerClose}>
        {indication === 'patient' && <PatientNav triggerClose={closeHamburger} />}
        {indication === 'hcp' && <HcpNav triggerClose={closeHamburger} />}
      </NavBar>
    </div>
  );
}

Nav.propTypes = {
  indication: PropTypes.string.isRequired,
};
